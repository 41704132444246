<template>
  <div class="NewsInformation">
    <!-- logo -->
    <div>
      <!-- <Header /> -->
      <Img />
    </div>
    <div class="container">
            <div class="container text-center">
                <h3>新闻动态</h3>
                <p style="color:#b2b2b2">Company News</p>
            </div>
            <!-- <div class="nav container text-center">
                <a href="#" class="active">公司新闻</a>
                 <a href="#">行业动态</a> 
            </div> -->
            <ul class="news-container container-fuild">
                <li v-for="(item,index) in tableData" :key="index" @click="go(item.id)" class="wow fadeIn">
                    <div class="content">
                        <p>{{item.articleTitle}}</p>
                        <!-- <el-button type="text">了解更多</el-button> -->
                        <!-- <p v-html="item.articleContext"></p> -->
                    </div>
                    <div class="time">
                        <!-- <p>{{item.date}}</p> -->
                        <span>{{item.gmtCreate | formatDate}}</span>
                    </div>
                    <div class="circle">
                        <img src="../assets/img/circle.png">
                        <i class="line center-block"></i>
                    </div>
                </li>
            </ul>
            <div class="contaianer-fuild text-center more">
                <i class="glyphicon glyphicon-th" @click="change"></i>
            </div>
        </div>
    <!-- main -->
    <!-- <div class="menu">
      <div class="main">
        <el-table @row-click="Dtails" :data="tableData" style="cursor:pointer;">
          <el-table-column label="动态要闻" prop="articleTitle"></el-table-column>
          <el-table-column align="right" label="发布时间" prop="gmtCreate"> 
            <template slot-scope="scope">
              {{scope.row.gmtCreate  | formatDate(scope.row.gmtCreate)}}
            </template>
          </el-table-column>
          <el-table-column align="right">
            <template #header>
                <el-input v-model="search" size="mini" placeholder="输入关键字搜索"/>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @current-change="handleCurrentChange" layout="prev, pager, next" :total="100"></el-pagination>
      </div>
    </div> -->
    <!-- 这里是页脚 -->
    <Footer />
  </div>
</template>

<script>
import {formatDate} from '../network/cldate.js'
import Header from '../components/hearder.vue'
import Footer from '../components/footer.vue'
import Img from '../components/img.vue'
import Menu from '../components/menu.vue'
import {post,get} from '../network/request'
export default {
    data() {
        return {
          tabPosition: 'left',
          tableData: [],
          page:1,
          limt:10,
          id:'',
      }
    },
    filters:{
      formatDate(time) {
        time = time * 1000
        let date = new Date(time)
        // console.log(new Date(time))
        return formatDate(date, 'yyyy-MM-dd ')
      }

    },
    created(){
      console.log('Created')
      // this.getmoduleData()
      // this.getData(1)
      this.id = this.$route.params.id
    },
    mounted(){
      this.getList()
    },
     methods: {
       getmodulearticle(id){
         console.log(id)
         this.getData(id)
       },
      change(){
        // console.log(1);
        this.page +=1
         get('/ess/ess-article/getAllArticle/' + this.id + '/' + this.page +'/' +this.limt,{

        }).then(res=>{
          // typeof()
          // console.log(typeof(res.data.data.data.length));
          if(res.data.data.data.length === 0){
            this.$message('没有更多了')
          }else{
            // console.log(1);
            this.tableData = res.data.data.data
          }
          
          // for(const i of res.data.data.data){
          //           // console.log(i.id);
          //           get('/ess/ess-article/get/' + i.id,{

          //           }).then(res=>{
          //             console.log(res);
          //               // console.log(res.data.data);
          //               this.tableData.push(res.data.data)
          //           })
          //       }
        }).catch(err=>{
          console.log(err);
        })
      } ,
      // handleEdit(index, row) {
      //   console.log(index, row);
      // },
      // handleDelete(index, row) {
      //   console.log(index, row);
      // },
      go(val) {
        // console.log(row.id);
        this.$router.push({path:'/details',query:{id:val}})
      },
      getList(id){
        get('/ess/ess-article/getAllArticle/' + this.id + '/' + this.page +'/' +this.limt,{

        }).then(res=>{
          
          this.tableData = res.data.data.data
          // for(const i of res.data.data.data){
          //           // console.log(i.id);
          //           get('/ess/ess-article/get/' + i.id,{

          //           }).then(res=>{
          //             console.log(res);
          //               // console.log(res.data.data);
          //               this.tableData.push(res.data.data)
          //           })
          //       }
        }).catch(err=>{
          console.log(err);
        })
      },
    },
    components:{
        Header,
        Footer,
        Img,
        Menu,
        }
}
</script>

<style lang="less" scoped>
.nav{
    margin: 20px 0;
}
.nav>a{
    display: inline-block;
    text-decoration: none;
    width: 120px;
    height: 45px;
    line-height: 45px;
    color: #333;
    border: 1px solid #333;
}
.nav>a.active{
    color: #1e73be;
    border-color: #1e73be;
}
.nav>a:hover{
    color: #1e73be;
    border-color: #1e73be;
}
.news-container{
    overflow: hidden;
    margin-bottom: 0;
}
.news-container>li{
    width: 55.6%;
    height: 120px;
    float: left;
    color: #333;
    text-align: right;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
}
.news-container>li:hover{
    color: #1e73be;
    border: 1px solid #1e73be;
    cursor: pointer;
}
.news-container>li:nth-of-type(2n){
    float: right;
    text-align: left;
}
.news-container>li>.content{
    width: 60%;
    float: left;
    padding: 20px 0;
}
.news-container>li>.content>p{
   font-size: 18px;
}
.news-container>li>.time{
    width: 20%;
    float: left;
    padding: 10px 0;
}
.news-container>li>.time>span{
    font-size: 20px;
    margin: 5px 0;
}
.news-container>li>.circle{
    width: 20%;
    height: 100%;
    float: left;
    position: relative;
}
.news-container>li>.circle>img{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 20px;
    height: 20px;
}
.center-block {
    display: block;
    margin-right: auto;
    margin-left: auto;
}
.news-container>li>.circle>i{
    display: block;
    width: 1px;
    height: 100%;
    background: #707070;
}
.news-container>li:nth-of-type(2n)>.content{
    float: right;
}
.news-container>li:nth-of-type(2n)>.time{
    float: right;
}
.news-container>li:nth-of-type(2n)>.circle{
    float: right;
}
.news-container>li:nth-of-type(1)>.circle>i{
    height: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
}
.more{
    font-size: 25px;
    color: #707070;
}
.more>i{
    cursor: pointer;
}
@media screen and (max-width: 767px){
    .news-container>li{
        width: 100%;
    }
    .news-container>li>.content{
        width: 70%;
        text-align: left;
        float: right;
    }
    .news-container>li>.time{
        width: 30%;
        text-align: left;
        float: right;
    }
    .news-container>li>.circle{
        display: none;
    }
}
</style>